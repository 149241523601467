import * as React from "react"
import Seo from "src/components/seo"
import AppLayout from "src/components/app/app-layout"
import {graphql} from "gatsby"

const title = "はじめてのずかん 個人情報保護方針";

const PrivacyContent = () => {
  return (
    <>
      <h1>{title}</h1>
      <section>
        <p>当社は、当社が提供するアプリケーションサービス「はじめてのずかん」（以下（「本アプリ」といいます。）におけるユーザー情報等の適切な取扱いの確保のため、以下のとおり、はじめてのずかん
          個人情報保護方針（以下「本保護方針」といいます。）を定めます。なお、本保護方針の用語の意義は別段の定めのない限り、本アプリの利用規約（以下「利用規約」といいます。）の定義に従います。</p>
      </section>

      <section>
        <h2>第1条（総則）</h2>
        <ol>
          <li>当社は、ユーザー情報等の保護実現のため、個人情報保護法その他関連する法令等を遵守いたします。本保護方針は、本アプリ（本アプリに付随して当社が提供するサービスを含みます。）の利用に関して適用されます。</li>
          <li>当社が当社の運営するウェブサイト上に掲載する個人情報保護方針又は利用規約においてユーザー情報等の取扱いについて規定する場合、当該規約等の規定（利用目的等）に加えて、本保護方針の規定（利用目的等）が適用されるものとします。規約等の規定が本保護方針の規定と抵触する場合には、本保護方針の規定が優先されるものとします。</li>
        </ol>
      </section>

      <section>
        <h2>第2条（取得する情報）</h2>
        <ol>
          <li>当社は、本アプリにおいて、以下に定める通り、個人情報（個人情報保護法第２条第１項により定義された「個人情報」をいい、以下同様とします。）を含むユーザー情報等を取得します。
            <ol>
              <li>ユーザーに提供いただく情報
                <ol>
                  <li>本アプリ内でユーザーが入力した情報</li>
                </ol>
              </li>
              <li>本アプリの利用時に当社が収集する情報
                <ol>
                  <li>端末情報</li>
                  <li>ログ情報及び行動履歴情報</li>
                  <li>Cookie及び匿名ID</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>当社は、ユーザー情報等の取得にあたっては、偽りその他不正の手段によらず、適正な方法により取得します。</li>
        </ol>
      </section>

      <section>
        <h2>第3条（利用目的）</h2>
        <ol>
          <li>当社は、本アプリの利用を通じて取得したユーザー情報等を、下記の目的の範囲内で適正に取り扱います。
            <ol>
              <li>本アプリにおける本人確認及び不正利用の防止のため</li>
              <li>本アプリの機能向上のため</li>
              <li>コミュニケーション機能、提携サービスへの連携を含む本アプリの円滑な提供、維持及び改善のため</li>
              <li>本アプリに関するユーザーへのアンケート等の実施のため</li>
              <li>本アプリに関する案内、問い合わせ等への対応のため</li>
              <li>本アプリに関連する新たなサービスの通知又は提供のため</li>
              <li>本アプリに関する利用規約又は本保護方針の変更、本アプリの停止・中止・契約解除その他本アプリの停止・中止・契約解除その他本アプリに関する重要なお知らせ等の通知のため</li>
              <li>関心、入力内容等の観点から分析することにより、個人を識別できない形式に加工した事例紹介用個別データ又は統計データ（以下「統計データ等」といいます。）を作成するため</li>
              <li>その他上記各号に付随する目的のため</li>
            </ol>
          </li>
          <li>当社及び統計データ等の提供を受けた第三者は、統計データ等を以下の目的のために利用します。
            <ol>
              <li>本アプリの紹介・宣伝のため</li>
              <li>広告効果分析のため</li>
              <li>市場分析、マーケティングのため</li>
              <li>その他上記各号に付随する目的のため</li>
            </ol>
          </li>
          <li>当社は、ユーザー情報等の利用目的を変更した場合には、当社が別途定める方法により、ユーザーに通知又は公表します。</li>
        </ol>
      </section>

      <section>
        <h2>第4条（第三者提供）</h2>
        <ol>
          <li>当社は、ユーザー情報等のうち、個人情報については、以下の場合を除き、第三者に提供することはありません。
            <ol>
              <li>ユーザーの同意を得た場合</li>
              <li>法令に基づく場合</li>
              <li>本アプリの利用上、ユーザーが他人の利益を害し若しくは公序良俗に反する行為その他本アプリの利用規約に違反する行為を行い又は行おうとするときに、当該行為に対して必要な措置を取る場合</li>
              <li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
              <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
              <li>国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
              <li>合併、会社分割、事業譲渡その他の理由によりユーザーの個人情報を含む事業の承継がなされる場合</li>
            </ol>
          </li>
          <li>ユーザーが提携サービス等を利用する場合、当社は、個人情報を含むユーザー情報等を提携事業者等に提供することができるものとし、ユーザーは当該提供について同意したものとみなします。</li>
        </ol>
      </section>

      <section>
        <h2>第5条（個人情報の取扱いの委託）</h2>
        <p>当社は、ユーザーから取得した個人情報の全部又は一部の取扱いを第三者に委託することがあります。この場合、当社は、当該委託先において情報の適切な安全管理が図られるよう、必要かつ適切な監督を行います。</p>
      </section>

      <section>
        <h2>第6条（共同利用）</h2>
        <ol>
          <li>当社は、提携事業者等との間で、提携サービス等の提供に必要な範囲において、ユーザー情報等を共同利用することがあります。</li>
          <li>共同利用目的等は、以下のとおりとします。
            <ol>
              <li>共同して利用するユーザー情報等の範囲
                <ol>
                  <li>ユーザーが入力した情報</li>
                </ol>
              </li>
              <li>共同して利用する者の範囲
                <ol>
                  <li>提携事業者</li>
                </ol>
              </li>
              <li>利用目的
                <ol>
                  <li>本アプリ及び提携サービス等の円滑な運用のため</li>
                  <li>利用状況等の調査及び分析のため</li>
                  <li>ユーザーからの問い合わせ等に適切に対応するため</li>
                </ol>
              </li>
              <li>共同利用する情報に関する問い合わせ窓口
                <ol>
                  <li>当社（問い合わせ先：<a target="_blank"
                                           href="https://dan-ran.co.jp/contact"> https://dan-ran.co.jp/contact</a>）
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </section>

      <section>
        <h2>第7条（情報収集モジュール）</h2>
        <p>本アプリには、本アプリの利用状況及び本アプリを含むサービス又は商品に関する広告効果等の情報を解析するため、情報収集モジュールが組み込まれています。これに伴い、情報収集モジュールの提供者に対し、ユーザー情報等の提供を行う場合があります。これらの情報収集モジュールは、個人を特定する情報を含むことなくユーザー情報等を収集し、収集された情報は、各情報収集モジュール提供者の定めるプライバシーポリシーその他の規定に基づき管理されます。</p>
      </section>

      <section>
        <h2>第8条（行動ターゲティング広告）</h2>
        <ol>
          <li>当社は、当社又は広告配信事業者等の第三者によるターゲティング広告（ユーザーのニーズないし興味、関心に合わせて広告を配信する広告手法をいいます。）の実施のため、本アプリ又は提携サービス等の提供に際し、前条に定める情報収集モジュールを利用して、以下の情報を収集し、これを蓄積又は利用します。
            <ol>
              <li>行動履歴情報</li>
              <li>端末情報</li>
              <li>位置情報</li>
            </ol>
          </li>
          <li>当社は、前項に基づき収集した情報を、ユーザーのニーズないし興味、関心に合わせてカスタマイズした最適な広告を配信することにより、ユーザーに有益な情報を届けることを目的として、行動ターゲティング広告を配信する事業者（以下「広告配信事業者」といいます。）に提供します。</li>
        </ol>
      </section>
      <section>
        <h2>第9条（本保護方針の変更）</h2>
        <ol>
          <li>当社は、ユーザー情報等の取扱いに関する運用状況等を適宜見直し、継続的な改善に努めるものとし、必要に応じて、随時本保護方針を変更することがあります。</li>
          <li>変更後の本保護方針については、本アプリ上又は当社の運営するウェブサイトでの掲示その他当社所定の方法により告知します。</li>
        </ol>
      </section>
      <section>
        <h2>第10条（規約等）</h2>
        <p>本保護方針に定めのない事項については、当社の規約等におけるユーザー情報等の取扱いの規定に従います。</p>
      </section>

      <section>
        <h2>第11条（お問い合わせ）</h2>
        <p>当社のユーザー情報等の取扱いに関するご意見、ご質問、苦情のお申出その他ユーザー情報等の取扱いに関するお問い合わせは、下記窓口までご連絡ください。</p>
      </section>

      <footer className="privacy-page__footer">
        <p>株式会社DanRan 問い合わせフォーム</p>
        <p><a target="_blank" href="https://dan-ran.co.jp/contact">https://dan-ran.co.jp/contact</a></p>
        <p className="privacy-page__revision">2021年1月15日 制定</p>
        <p className="privacy-page__revision">2024年7月24日 改定</p>
      </footer>

    </>
  )
}

const MyFirstLibraryPrivacy = () => {
  return (

    <AppLayout isShownHeader={false}>
      <Seo title={title} isIndex={false}/>

      <article className="container page-article-main privacy-page">
        <PrivacyContent/>
      </article>

    </AppLayout>
  )
    ;
}

export default MyFirstLibraryPrivacy

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
